import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useNavigation } from 'react-navi'
import { Typography } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#4285F3',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between'
  },
}));



export default function MyBreadcrumbs(props) {
  const classes = useStyles();
  let navigation = useNavigation();

  function handleClick(path) {
    navigation.navigate(path)
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator={<span style={{ color: 'white' }}>/</span>} aria-label="breadcrumb">
          {props.data.map((el, index) =>
            <Link key={index} style={{ cursor: 'pointer', color: 'white' }} onClick={() => handleClick(el.path)}>
              {el.title}
            </Link>
          )}
        </Breadcrumbs>
        <div>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {props.title}
          </Typography>

        </div>
      </Paper>

    </div>
  );
}