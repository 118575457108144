import React from 'react'
import { mount, route, withView, redirect, lazy, map, compose } from 'navi';
import LoginPage from '../Pages/LoginPage';
import Layout from '../Layout';
import { View } from 'react-navi';
import { GetData } from '../Service/service';
import PrintDashboard from '../Pages/Print/PrintDashboard';
import ChatBox from '../Pages/Home/ChatBox';

export default compose(
    withView((request, context, args) => <Layout currentUser={context.currentUser} authService={context.authService}>
        <View />
        {/* {context.currentUser && <ChatBox currentUser={context.currentUser} authService={context.authService} />} */}
    </Layout>),
    mount({
        // '/login': map((request, context) => route({
        //     title: 'Login Page',
        //     view: <LoginPage currentUser={context.currentUser} authService={context.authService} />
        // })),
        '/login': map((request, context) => context.currentUser ? redirect('/crc')
            : route({
                title: 'Login Page',
                view: <LoginPage currentUser={context.currentUser} authService={context.authService} />
            })),
            '/': map((request, context) => {
                if (!context.currentUser) {
                    return redirect('/login')
                }
               
                return redirect('/crc')
            }),
        '/crc': lazy(() => import('./SecuredRoutes')),
        '/print/:type/:id': map((request, context) => {
            if (!context.currentUser) {
                return route({
                    title: 'Login',
                    view: <LoginPage authService={context.authService}  />
                })
            }

            let type = request.params.type;
            let id = request.params.id;

            return GetData(`/${context.authService.getApikey()}/${type}/${id}/getprintdata`)
                .then((resp) => {
                    console.log(resp)
                    if(resp.status == true){
                        
                        return route({
                            title: 'Print Page',
                            view: <PrintDashboard 
                                authService={context.authService} 
                                type={type} 
                                id={id}
                                printData={resp.status_text} />
                        })
                    }
                })
            
        })
    })
)