import React, { useState } from "react";
import { useNavigation } from "react-navi";
import { makeStyles, createStyles } from "@material-ui/styles";
import {  Typography, TextField, Button, InputAdornment, OutlinedInput, InputLabel } from "@material-ui/core";
import { PostData } from "../Service/service";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
    },
    fontLarge: {
      fontSize: "9em",
      fontWeight: "bold",
      color: "black",
    },
  })
);

export default function LoginPage({ currentUser, authService }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  let navigation = useNavigation();

  function onSubmit(e) {
    e.preventDefault();

    if (username != "" && password != "") {
      // login and flush the error
      setIsLoading(true);

      PostData("/login", { username: username, password: password,account_type:'SUPERADMIN' }).then(
        (resp) => {
          //console.log("🚀 ~ file: LoginPage.js ~ line 43 ~ .then ~ resp", resp);
          authService.setTokenAfterLogin(resp).then((resp) => {
            setIsLoading(false);
            if (resp == true) {
              navigation.navigate("/crc").then(() => {
                window.location.reload();
              });
            } else {
              setError(resp);
            }
          });
        }
      );
    } else {
      setError("Please insert all fields");
    }
  }
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div
          align="center"
          style={{
            backgroundColor: "white",
            minHeight: 420,
            width: 450,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 8,
            boxShadow: "10px 10px 20px rgba(0,0,0,0.7)",
          }}
        >
          
          <img
             src={`/images/logo.jpeg`}
            style={{
              width: "60%",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              marginBottom: 10,
            }}
          />
<Typography  style={{fontSize:'15px'}}>ADMIN</Typography>
          <form onSubmit={onSubmit}>
            <div
              align="left"
              style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}
            >
              <TextField
                required
                error={Boolean(error)}
                fullWidth
                label="Username"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            fullWidth
            label="Password"
            required
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPassword(!showPassword)}
                  onMouseDown={()=>setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />

              <div align="right">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Login
                </Button>
              </div>
              {error != "" ? (
                <Typography style={{ color: "red" }} variant="caption">
                  {JSON.stringify(error)}
                </Typography>
              ) : null}

                  <br />

                <Typography style={{ color: "blue" }} variant="caption">
                  Forgot Password ? Search your SMS Inbox with CASHBY.
                  </Typography>

            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
