export const APP_NAME = "CASHBYTE";
export const DEFAULT_COLOR = "#000000";
export const SECONDARY_DEFAULT_COLOR = "#E9A737";


export const DRAWER_WIDTH_LOW = 90;
export const DRAWER_WIDTH_HIGH = 220;
export const DRAWER_HEIGHT = 80;
export const DISPLAY_NAME = `CASHBYTE`;
export const PRIMARY_COLOR = `#2A75BB`;

export const SERVER_NAME =   process.env.NODE_ENV === 'production' ? 'https://backend.cashbyte.org/public/public' : 'http://localhost/mlm_jahirul/api/public';
export const SERVER_NAME_FOR_UPLOADS =   process.env.NODE_ENV === 'production' ? 'https://backend.cashbyte.org/public' : 'http://localhost/mlm_jahirul/api/public';

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.cashbyte.org/public' : 'http://localhost/mlm_jahirul/api/public';
export const UploadPath = process.env.NODE_ENV === 'production' ? 'https://backend.cashbyte.org/src/uploads' : 'http://localhost/mlm_jahirul/api/src/uploads';

export const WS_SERVER = process.env.NODE_ENV === 'production' ? 'wss://cashbyte.nehhdcmis.in' : 'http://localhost:40004';

// export const ADMIN_ROLE = [
// "home",
// "master_home",
// "master_company",
// "master_admin",
// "master_coupon_price",
// "master_coupon",
// "master_coupon_commission",
// "master_prize",
// "master_prize_commission",
// "users",
// "user_matrix",
// "user_wallet",
// "user_wallet_topup",
// "user_prize",
// "game",
// "game_color_set",
// "game_voting_list",
// "user_buyer_list",
// "user_seller_list",
// "user_refer_list",
// "user_team_list",
// "user_fund_list",
// "reset",
// "logs"];

export const ADMIN_ROLE = [
    {
        text:"Home",
        value:"home"
    },
    {
        text:"Master (Home)",
        value:"master_home"
    },
    {
        text:"Master (Company Details)",
        value:"master_company"
    },
    {
        text:"Master (Admin List)",
        value:"master_admin"
    },
    {
        text:"Master (Company Account)",
        value:"master_company_account"
    },
    {
        text:"Users (Home)",
        value:"users"
    },
    {
        text:"Users (User Matrix)",
        value:"user_matrix"
    },
    {
        text:"Users (User Wallet)",
        value:"user_wallet"
    },
    {
        text:"Users (Wallet Top Up)",
        value:"user_wallet_topup"
    },
    {
        text:"User (Money Request)",
        value:"user_money_request"
    },
    {
        text:"Game (Home)",
        value:"game"
    },
    {
        text:"Game (Status Change)",
        value:"game_status_change"
    },
    {
        text:"Game (Ticket Sold)",
        value:"game_ticket_sold"
    },
    {
        text:"Game (Result)",
        value:"game_result"
    },
    {
        text:"Game (Winner List)",
        value:"game_winner_list"
    },
    // {
    //     text:"Settings (Database Reset)",
    //     value:"reset"
    // },
    {
        text:"Settings (Logs)",
        value:"logs"
    },
    {
        text:"Logout",
        value:"logout"
    }
]


export default function number2text(value) {
    var fraction = Math.round(frac(value)*100);
    var f_text  = "";

    if(fraction > 0) {
        f_text = "and "+convert_number(fraction)+" paise";
    }

    //return convert_number(value)+" rupee "+f_text+" only";
    return convert_number(value)+"  "+f_text+"rupees Only.";
}

function frac(f) {
    return f % 1;
}

function convert_number(number)
{
    if ((number < 0) || (number > 999999999)) 
    { 
        return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000);  /* Crore */ 
    number -= Gn * 10000000; 
    var kn = Math.floor(number / 100000);     /* lakhs */ 
    number -= kn * 100000; 
    var Hn = Math.floor(number / 1000);      /* thousand */ 
    number -= Hn * 1000; 
    var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
    number = number % 100;               /* Ones */ 
    var tn= Math.floor(number / 10); 
    var one=Math.floor(number % 10); 
    var res = ""; 

    if (Gn>0) 
    { 
        res += (convert_number(Gn) + " crore"); 
    } 
    if (kn>0) 
    { 
            res += (((res=="") ? "" : " ") + 
            convert_number(kn) + " lakh"); 
    } 
    if (Hn>0) 
    { 
        res += (((res=="") ? "" : " ") +
            convert_number(Hn) + " thousand"); 
    } 

    if (Dn) 
    { 
        res += (((res=="") ? "" : " ") + 
            convert_number(Dn) + " hundred"); 
    } 


    var ones = Array("", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen","Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen","Nineteen"); 
var tens = Array("", "", "Twenty", "Thirty", "Fourty", "Fifty", "Sixty","Seventy", "Eighty", "Ninety"); 

    if (tn>0 || one>0) 
    { 
        if (!(res=="")) 
        { 
            res += " and "; 
        } 
        if (tn < 2) 
        { 
            res += ones[tn * 10 + one]; 
        } 
        else 
        { 

            res += tens[tn];
            if (one>0) 
            { 
                res += ("-" + ones[one]); 
            } 
        } 
    }

    if (res=="")
    { 
        res = "zero"; 
    } 
    return res;
}
