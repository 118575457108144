import { NOTIFICATION_ON, NOTIFICATION_OFF, LOAD_PARTY, ADD_PARTY, EDIT_PARTY, ACCESS, TOGGLE_COLLAPSE, GET_SERVICES, ADD_TEST, GET_BANKS, GET_CATEGORY, ADD_NEW_TERM, GET_COMPANY_DETAILS, GET_BRANCH, GET_DEPARTMENT, GET_DESIGNATION, GET_GRADE, GET_EMPLOYMENT_TYPE, GET_INSURANCE, GET_LEAVE_TYPE, GET_GRIEVANCE_TYPE, GET_SESSION, GET_HOLIDAY, GET_NOTICE, GET_EMPLOYEE, GET_EMPLOYEE_LEAVE, GET_EMPLOYEE_ATTENDANCE, SOCKET_ON, GET_PAYHEAD, GET_MESSAGE, GET_PAYROLL, GET_FESTIVAL_ADVANCE, GET_PAYMENT_RECEIPT, GET_ADMIN_LIST, GET_COUPON_PRICE_LIST, GET_COUPON_LIST, GET_COUPON_COMMISSION_LIST, GET_MASTER_PRIZE_LIST, GET_PRIZE_COMMISSION_LIST, GET_USER_LIST, GET_MASTER_PACKAGE, GET_LEVEL_INCOME, GET_COMPANY_WALLET} from "../constants/action-types";

const initialState = {
    notification_type: '',
    notification_status: '',
    notification_text: '',
    notification_action:false,
    notification_link:'',
    selectedKeys: ['home'],


    collapse: false,
    drawerWidth: 120,

    companyDetails: {},
    isCompanyDetailsLoaded: false,


    adminList: [],
    isAdminLoaded: false,


    masterPackageList: [],
    isMasterPackageLoaded: false,


    masterLevelIncome: [],
    isMasterLevelIncomeLoaded: false,

    couponCommissionList: [],
    isCouponCommissionListLoaded: false,

    masterPrizeList: [],
    isMasterPrizeListLoaded: false,

    prizeCommissionList: [],
    isPrizeCommissionListLoaded: false,


    socket: {},
    message:[],
    isMessageLoaded:false,
    walletBalance:0,
    isWalletLoaded:false


};
function rootReducer(state = initialState, action) {



    if (action.type === NOTIFICATION_ON) {
        return Object.assign({}, state, {
            notification_type: action.payload.type,
            notification_status: action.payload.status,
            notification_text: action.payload.text,
            notification_action: action.payload.action,
            notification_link: action.payload.link
        });
    }

    if (action.type === NOTIFICATION_OFF) {
        return Object.assign({}, state, {
            notification_type: '',
            notification_status: '',
            notification_text: '',
            notification_action:false,
            notification_link:''
        });
    }


   
    if (action.type === GET_COMPANY_DETAILS) {
        return Object.assign({}, state, {
            companyDetails: action.payload,
            isCompanyDetailsLoaded: true
        });
    }


    // ADMIN
    if (action.type === GET_ADMIN_LIST) {
        return Object.assign({}, state, {
            adminList: action.payload,
            isAdminLoaded: true
        });
    }

      // WALLET
      if (action.type === GET_COMPANY_WALLET) {
        return Object.assign({}, state, {
            walletBalance: action.payload,
            isWalletLoaded: true
        });
    }




if (action.type === GET_MESSAGE) {
    return Object.assign({}, state, {
        message: action.payload,
        isMessageLoaded: true
    });
}

if (action.type === SOCKET_ON) {
    return Object.assign({}, state, {
        socket: action.payload
    });
}

    //do not delete
    if(action.type === TOGGLE_COLLAPSE){
        let dw = 200;
        if(state.collapse){
            dw = 80;
        }else{
            dw = 200
        }

        if(action.payload === null){
            return Object.assign({}, state, {
                collapse: !state.collapse,
                drawerWidth: dw
             });
        }else{
            return Object.assign({}, state, {
                collapse: action.payload,
                drawerWidth: dw
             });
        }
        
    }



    return state;
}
export default rootReducer;