import {
  NOTIFICATION_ON,
  NOTIFICATION_OFF,
  SET_NEW_MENU_KEY,
  T_STATUS_SHOW,
  T_STATUS_LOADING,
  T_STATUS_CLOSE_LOADING,
  SET_NEW_FONT,
  LOAD_PARTY,
  ADD_PARTY,
  EDIT_PARTY,
  ACCESS,
  TOGGLE_COLLAPSE,
  GET_SERVICES,
  GET_BANKS,
  GET_CATEGORY,
  GET_COMPANY_DETAILS,
  GET_BRANCH,
  GET_DEPARTMENT,
  GET_DESIGNATION,
  GET_GRADE,
  GET_EMPLOYMENT_TYPE,
  GET_INSURANCE,
  GET_LEAVE_TYPE,
  GET_GRIEVANCE_TYPE,
  GET_SESSION,
  GET_HOLIDAY,
  GET_NOTICE,
  GET_EMPLOYEE,
  GET_EMPLOYEE_LEAVE,
  GET_EMPLOYEE_ATTENDANCE,
  SOCKET_ON,
  GET_PAYHEAD,
  GET_MESSAGE,
  GET_PAYROLL,
  GET_FESTIVAL_ADVANCE,
  GET_PAYMENT_RECEIPT,
  GET_ADMIN_LIST,
  GET_COUPON_PRICE_LIST,
  GET_COUPON_LIST,
  GET_COUPON_COMMISSION_LIST,
  GET_MASTER_PRIZE_LIST,
  GET_PRIZE_COMMISSION_LIST,
  GET_USER_LIST,
  GET_MASTER_PACKAGE,
  GET_LEVEL_INCOME,
  GET_COMPANY_WALLET
} from '../constants/action-types'
import { GetData, SecuredGetData } from '../../Service/service'
import { authService } from '../../Helper/AuthService'
import SocketService from '../../Helper/SocketService'



export function showNotification({ type, status, text,action,link }) {
  return function (dispatch) {
    dispatch({ type: NOTIFICATION_ON, payload: { type, status, text,action,link } })
    setTimeout(() => dispatch({ type: NOTIFICATION_OFF, payload: '' }), 2000)
  }
}

export function changeFontSettings({ fontSettings }) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_FONT, payload: fontSettings })
  }
}

export function showToast({ status, text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_SHOW, payload: { status, text } })
  }
}

export function showLoading({ text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_LOADING, payload: { text } })
  }
}

export function closeLoading() {
  return function (dispatch) {
    dispatch({ type: T_STATUS_CLOSE_LOADING, payload: '' })
  }
}

export function setNewKey(data) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_MENU_KEY, payload: data })
  }
}

// company details

export function getCompanyDetails() {
  return function (dispatch) {
    return SecuredGetData(`/getcompanydetails`).then((resp) => {
      dispatch({ type: GET_COMPANY_DETAILS, payload: resp })
    })
  }
}

// Branch
export function getAdminList() {
  
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getalladmins`).then(
        (resp) => {
          dispatch({ type: GET_ADMIN_LIST, payload: resp.data })
        },
      )
    }
  }
}

// Wallet
export function getCompanyWallet() {
  
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/COMPANY/getmywallet`).then(
        (resp) => {
          dispatch({ type: GET_COMPANY_WALLET, payload: resp.status?parseFloat(resp.data?.amount).toFixed(2):0.00 })
        },
      )
    }
  }
}





// export function getMessage() {
//   return function (dispatch) {
//     return SecuredGetData(`/getmessage`).then((resp) => {
//       let t = resp.map((el)=>el.message)
//       dispatch({ type: GET_MESSAGE, payload: t })
//     })
//   }
// }

export function initiateSocket() {
  return function (dispatch) {
      let socket = new SocketService();
      dispatch({ type: SOCKET_ON, payload: socket })
  }
}

export function toggleCollapseDashboard(value) {
  //console.log("🚀 ~ file: index.js ~ line 153 ~ toggleCollapseDashboard ~ value", value)
  return function (dispatch) {
    dispatch({ type: TOGGLE_COLLAPSE, payload: value })
  }
}