import decode from 'jwt-decode';

export const authService = {
    setTokenAfterLogin(res){
        if (res.hasOwnProperty('token')) {
            //console.log(res)
            this.setToken(res.token);
            this.setUser(res.user);
            this.setApikey(res.user.apikey);
            return Promise.resolve(true);
        }else{
            return Promise.resolve(res)
        }
    },
    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token) // handwaiving here
    },
    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    },
    setUser(user) {
        const val = JSON.stringify(user);
        localStorage.setItem('user', val);
    },
    setApikey(key) {
        const val = JSON.stringify(key);
        localStorage.setItem('apikey', val);
    },
    getApikey() {
        return JSON.parse(localStorage.getItem('apikey'));
    },
    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    },
    getUserName() {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.email;
    },
    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    },
    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    },
    logout() {
        // Clear user token and profile data from localStorage
        localStorage.clear();
        window.location.href = "/"
    },
    getProfile() {
        let user = this.getUser();
        return user;
    },
    getCurrentUser() {
        if (!this.loggedIn) {
            return null;
        } else {
            return this.getProfile();
        }
    },
    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}

try {
    authService.currentUser = authService.getCurrentUser()
} catch (e) {

}